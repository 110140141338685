import { Splide, SplideSlide, SplideTrack } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import slides from "./servicesData";
import React, { useState } from "react";
import './services.css'
const AccordionItem = ({ title, content }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="accordion-item mt-4 ">
      <button className="accordion-button mt-2 text-darkGreen text-xl hover:font-semibold" onClick={toggleAccordion}>
        {title}
      </button>
      {isOpen && <div className="accordion-content text-darkGreen">{content}</div>}
    </div>
  );
};

const Accordion = ({ items }) => {
  return (
    <div className="accordion divide-y divide-darkGreen ">
      {items.map((item, index) => (
        <AccordionItem key={index} title={item.title} content={item.content} />
      ))}
    </div>
  );
};

const Services = () => {
  const accordionItems = [
    {
      title: "PORTARIA E RECEPÇÃO",
      content: "Nossa equipe de Portaria e Recepção está aqui para receber todos com um sorriso. Com atenção e cuidado, nossos porteiros e recepcionistas estão prontos para auxiliar visitantes e colaboradores. Eles controlam o acesso para manter o local seguro e também podem ajudar com informações básicas. Conte conosco para criar um ambiente amigável desde a entrada.",
    },
    {
      title: "MONITORAMENTO",
      content: "A equipe de Monitoramento trabalha discretamente para garantir a segurança. Com câmeras e atenção constante, nossos profissionais zelam pelo espaço. Em caso de qualquer situação suspeita, eles estão preparados para tomar medidas adequadas. Priorizamos a proteção do seu ambiente.",
    },
    {
      title: "CONSERVAÇÃO E LIMPEZA",
      content: "Mantenha seu espaço sempre apresentável com a equipe de limpeza!. Nossos especialistas mantêm tudo limpo e organizado, para que você possa trabalhar em um ambiente agradável. Além disso, oferecemos cuidados básicos de conservação para prolongar a vida útil do local.",
    },
  ];

  const options = {
    type: "loop",
    gap: "5rem",
    autoplay: true,
    perPage: 1,
    pauseOnHover: false,
    resetProgress: false,
    height: "auto",
    focus: "center",
    arrows: true,
    pagination: false,
  };

  return (
    <>
      <div id="Servicos" className="lg:pt-[9rem] md:pt-[9rem] sm:pt-[1em] pb-10 grid lg:grid-cols-2 sm:grid-cols-1 md:grid-cols-2 ">
        <div className="lg:pb-[6rem] md:pb-[6rem] sm:pb-[2rem]">
          <Splide
            options={options}
            aria-labelledby="objetivos conteúdo"
            hasTrack={false}
            className="mx-2 slide-mobile "
          >
            <div style={{ position: "relative" }}>
              <SplideTrack className="pt-10 pb-10 mb-2  ">
                {slides.map((slide, index) => (
                  <SplideSlide key={index} className="splide-slide-img px-4">
                    <div className="bg-[#DBE7E8] rounded-md py-6">
                      <div className="">
                        <img
                          className="w-full rounded-3xl"
                          src={slide.src}
                          alt={slide.alt}
                        />
                      </div>
                    </div>
                  </SplideSlide>
                ))}
              </SplideTrack>
            </div>
          </Splide>
        </div>
        <div className="sm:mx-4">
          <h1 className="text-7xl lg:pt-[20rem] lg:mt-12 md:pt-8 md:mt-8 sm:pt-2 sm:mt-2 text-darkGreen font-bold ">Serviços</h1>
          <p className="mt-2 text-darkGreen">
            Nossa empresa oferece soluções abrangentes, desde monitoramento
            eletrônico e montagem de centrais até serviços especializados de
            limpeza, pós-obra, fachadas e tratamento de pisos.
          </p>
          <Accordion items={accordionItems} className='' />
        </div>
      </div>
    </>
  );
};

export default Services;
