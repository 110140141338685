import decoration from '../../assets/footer/footer-decoration.png';

const Footer = () => {
    return (
        <>
            <footer className="bg-darkGreen">
                <div className="md:flex  lg:flex lg:space-x-12 md:space-x-12 sm:space-x-6 pt-5 mx-12 justify-between">
                    <div className="p-2">
                        <h2 className="text-diasBackground text-3xl mb-2 uppercase">Endereço</h2>
                        <p className="text-diasBackground"><strong>Rua Josefa Maria de Lima 212 Jd Tietê. São Paulo SP</strong></p>
                            <div>
                                <h2 className="text-diasBackground text-3xl mb-2 pt-12 uppercase">Contato</h2>
                                <p className="text-diasBackground">Telefone: <a href="tel:+5511947781711"><strong>11 94778-1711</strong></a></p>
                                <p className="text-diasBackground">Email: <a href="mailto:diasesilvaservicos@gmail.com"><strong>diasesilvaservicos@gmail.com</strong></a></p>
                            </div>
                    </div>

                    <div className="p-2 lg:p-2 md:pl-12 lg:pl-12  sm:pl-9 mt-2">
                        <h2 className="text-diasBackground text-3xl mb-2 uppercase sm:pt-2 ">Navegação</h2>
                        <p className="text-diasBackground"> <a href="#SobreNos">Sobre Nós</a></p>
                        <p className="text-diasBackground"> <a href="#Objetivos">Objetivos</a></p>
                        <p className="text-diasBackground"> <a href="#Servicos">Serviços</a></p>
                        <p className="text-diasBackground"> <a href="#Clientes">Clientes</a></p>
                        <p className="text-diasBackground"> <a href="#Contato">Contato</a></p>
                    </div>

                    <div>
                        <img src={decoration} alt='decoração de gavião' />
                    </div>
                
                </div>
            </footer>
        </>
    )
}

export default Footer;
