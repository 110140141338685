import atendimento from '../../assets/goals/atendimento.svg';
import solucoes from '../../assets/goals/solucoes.svg';
import sucesso from '../../assets/goals/sucesso.svg';

const slides = [
    {
        title: 'Atendimento Personalizado e Diferenciado',
        text: 'Nossa empresa se destaca pela abordagem personalizada e única para cada cliente. Buscamos compreender suas necessidades específicas, estabelecendo uma verdadeira parceria para oferecer soluções que impulsionem o alcance de suas metas comerciais.',
        src: atendimento,
        alt: 'atendimento',
    },
    {
        title: 'Soluções de Qualidade para o Sucesso',
        text: 'Nosso compromisso é oferecer serviços de alta qualidade, desenvolvendo soluções sob medida que atendam aos desafios únicos de cada cliente. Nosso foco é garantir que nossos clientes possam dedicar-se plenamente às suas atividades finais, sabendo que suas demandas estão em mãos experientes e confiáveis. ',
        src: solucoes,
        alt: 'Soluções',
    },
    {
        title: 'Facilitando o Caminho ao Sucesso',
        text: ' Acreditamos que ao aliviar as preocupações operacionais de nossos clientes, eles poderão concentrar seus esforços no crescimento e no desenvolvimento de seus negócios.Nossa equipe está empenhada em ser um suporte confiável e estratégico, auxiliando-os no caminho rumo ao sucesso e prosperidade. ',
        src: sucesso,
        alt: 'sucesso ',
    },
];

export default slides;