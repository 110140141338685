import sertaozinho from '../../assets/clients/logos/sertaozinho.png';
import cisItu from '../../assets/clients/logos/cis-itu-logo.png';
import saae from '../../assets/clients/logos/saae.png';

const slides = [
    {
        src: sertaozinho,
        alt: 'Sertãozinho',
    },
    {
        src: cisItu,
        alt: 'Cis Itu',
    },
    {
        src: saae,
        alt: 'SAAE',
    },
];

export default slides;
