import './clients.css';
import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';
import slides from './clientsData';


const Clients = () => {
    const options = {
        type: 'loop',
        gap: '0.2rem',
        autoplay: true,
        perPage: 6,
        pauseOnHover: false,
        resetProgress: false,
        height: 'auto',
        focus  : 'center',
        arrows: false ,
    };

    return (
        <>
    <section id='partners-section'>
        <div id="Clientes" className='sm:mt-[2rem] mt-[1rem]'>
            <div className='bg-diasBlue w-[35rem] rounded-r-full'>
                <h2 className='text-6xl text-darkGreen lg:ml-[16rem] md:ml-[16rem] sm:ml-[10rem] p-5'> Clientes</h2>
            </div>
        </div>
        <div className="wrapper pt-[5rem] mr-[-10px]" id="Brands">
        <Splide
            options={options}
            aria-labelledby="autoplay-example-heading"
            hasTrack={false}
        >
            <div style={{ position: 'relative' }}>
            <SplideTrack className="">
                {slides.map((slide, index) => (
                <SplideSlide key={index} className="splide-slide-img" >
                    <img  className="logo-brands" src={slide.src} alt={slide.alt} />
                </SplideSlide>
                ))}
            </SplideTrack>
            </div>
        </Splide>
        </div>
    </section>
        </>
    )
}

export default Clients;
