
const About = () => {
    return (
        <>
            <div id="SobreNos" className="lg:flex  justify-around lg:space-x-6  pt-[250px] lg:text-start md:text-center sm:text-center items-center container lg:mx-[6rem] md:mx-10 sm:mx-2">
                <div className="">
                    <h2 className="text-darkGreen text-6xl leading-[4rem]">Conheça a <span className="underline">Nossa História</span> Excelência em Serviços e Dedicação aos Clientes</h2>
                </div>
                <div className="md:pt-10 sm:pt-10">
                    <p className="text-xl text-darkGreen">A Dias & Silva é uma empresa prestadora em terceirização de serviços através de mão de obra especializada, administrada com profissionais de grande experiência dentro de cada segmento. </p>
                </div>
            </div>
        </>
    )
}

export default About;
