import React, { useState, useRef, useEffect } from "react";

import logo from "../../../assets/logo.svg";
import iconContact from "../../../assets/header/contact.svg";
import "./navbar.css";
import { MdClose } from "react-icons/md";
import { FiMenu } from "react-icons/fi";

const Navbar = () => {
    const [navbarOpen, setNavbarOpen] = useState(false);

  const ref = useRef();
  useEffect(() => {
    const handler = (event) => {
      if (navbarOpen && ref.current && !ref.current.contains(event.target)) {
        setNavbarOpen(false);
      }
    };
    document.addEventListener("mousedown", handler);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", handler);
    };
  }, [navbarOpen]);


  return (
    <>
      <nav className="navbar ">
        <button
          className="toggle"
          onClick={() => setNavbarOpen((prev) => !prev)}
        >
          {navbarOpen ? (
            <MdClose style={{ width: "32px", height: "32px" }} />
          ) : (
            <FiMenu
              style={{
                width: "32px",
                height: "32px",
              }}
            />
          )}
        </button>
        <ul className={`menu-nav${navbarOpen ? " show-menu" : ""}`}>
            <img src={logo} className="logo-navbar" alt="logo navbar"/>
          <li><a href="#SobreNos">Sobre</a></li>
          <li><a href="#Objetivos">Objetivos</a></li>
          <li><a href="#Servicos">Serviços</a></li>
          <li><a href="#Clientes">Clientes</a></li>
        </ul>
      </nav>

      <div className="navbar-desktop">
        <header className="flex justify-between  mx-4 text-xl items-center">
          <div className="mt-3">
            <img src={logo} className="h-[8rem]" alt='logo navbar'/>
          </div>

          <div className="mt-5 z-10 ">
            <ul className="flex gap-10 text-darkGreen lg:text-3xl md:text-2xl">
			  <li><a href="#SobreNos">Sobre</a></li>
			  <li><a href="#Objetivos">Objetivos</a></li>
			  <li><a href="#Servicos">Serviços</a></li>
			  <li><a href="#Clientes">Clientes</a></li>
            </ul>
          </div>

          <div className="mt-3 z-10 flex bg-darkGreen rounded-full h-[4rem] w-[13rem] px-4 gap-4 hover:shadow-lg items-center">
            <button className=" text-[#DBE7E8] text-3xl ml-4"><a href="#Contato">Contato</a></button>
            <img src={iconContact} className="h-5 w-5" alt="icon contact"/>
          </div>
        </header>
      </div>
    </>
  );
};

export default Navbar;
