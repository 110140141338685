
import { useReducer } from "react";

import emailjs from "@emailjs/browser";


const Contact = () => {

	const userInputReducer = (state, action) => {
		if (action.type === "USER_NAME") {
			return {name: action.value, email: state.email, subject: state.subject, message: state.message}
		}
		if (action.type === "USER_EMAIL") {
			return {name: state.name, email: action.value, subject: state.subject, message: state.message}
		}
		if (action.type === "USER_SUBJECT") {
			return {name: state.name, email: state.email, subject: action.value, message: state.message}
		}
		if (action.type === "USER_MESSAGE") {
			return {name: state.name, email: state.email, subject: state.subject, message: action.value}
		}
		if (action.type === "CLEAN") {
			return {name: "", email: "", subject: "", message: ""}
		}
	}

	const [userInputState, dispatchUserInput] = useReducer(userInputReducer, {firstName: "", lastName: "", email: "", message: ""})

	const nameChangeHandler = (event) => {
		dispatchUserInput({type: "USER_NAME", value: event.target.value})
	}

	const emailChangeHandler = (event) => {
		dispatchUserInput({type: "USER_EMAIL", value: event.target.value})
	}

	const subjectChangeHandler = (event) => {
		dispatchUserInput({type: "USER_SUBJECT", value: event.target.value})
	}

	const messageChangeHandler = (event) => {
		dispatchUserInput({type: "USER_MESSAGE", value: event.target.value})
	}

	const handleSubmit = () => {

		const params = {
			name: userInputState.name,
			subject: userInputState.subject,
			email: userInputState.email,
			message: userInputState.message,
			sender_email: "diasesilvaservicos@gmail.com",
			site: "Dias e Silva"
		}
		
		emailjs.send('service_uddyasa', 'template_ivv6k8p', params, 'VS1CAPTPS4iGH-mOb')
		.then((result) => {
			  console.log(result.text);
		  }, (error) => {
			  console.log(error.text);
		  });

		dispatchUserInput({type: "CLEAN"});
		console.log(userInputState);
	}
  
    return (
        <>
        <div id="Contato" className="lg:px-[10rem] md:px-[10rem] sm:px-[2rem] py-[15rem]">
            <div className="bg-darkGreen rounded-md">
            <h1 className="text-diasBackground text-6xl mx-8 pt-8">Contato</h1>
                <div className="pt-12 mx-8">
                    <p className="text-diasBackground text-2xl mx-2">Nome</p>
                    <input value={userInputState.name} onChange={nameChangeHandler} type="text" class="form-input px-2 py-2 rounded-2xl mt-2 w-full" />
                    <p className="text-diasBackground text-2xl mx-2 mt-5 w-fu">Email</p>
                    <input value={userInputState.email} onChange={emailChangeHandler} type="email" class="form-input px-2 py-2 rounded-2xl mt-2 w-full" />
                    <p className="text-diasBackground text-2xl mx-2 mt-5">Assunto</p>
                    <input value={userInputState.subject} onChange={subjectChangeHandler} type="text" class="form-input px-2 py-2 rounded-2xl mt-2 w-full" />
                    <p className="text-diasBackground text-2xl mx-2 mt-5">Mensagem</p>
                    <textarea value={userInputState.message} onChange={messageChangeHandler} type="text" class="form-input px-2 py-4 rounded-2xl mt-2 w-full" />
                </div>
                <button type="submit" onClick={handleSubmit} className="bg-diasBackground mx-9 mt-11 rounded-md text-darkGreen px-4 py-2 mb-10 hover:font-medium">Enviar</button>
            </div>
        </div>
            
        </>
    )
}

export default Contact;
