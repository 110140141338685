import { Splide, SplideSlide, SplideTrack } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import slides from "./goalsData";
import './goals.css';

const Goals = () => {
  const options = {
    type: "loop",
    gap: "5rem",
    autoplay: true,
    perPage: 2,
    pauseOnHover: false,
    resetProgress: false,
    height: "auto",
    focus: "center",
    arrows: false,
    paginationKeyboard: true,
    pagination: true,
    dots: true,
    breakpoints: {
      640: {
        perPage: 1,
      },
      480: {
        perPage: 1,
      },
    },
  };

  return (
    <>
      <div id="Objetivos" className="pt-[9rem] pb-10">
        <div className="bg-darkGreen pb-[6rem] pt-12">
          <h1 className="text-diasBackground mx-2 text-5xl pt-12 pb-12 ml-4">Objetivos</h1>

          <Splide
            options={options}
            aria-labelledby="objetivos conteúdo"
            hasTrack={false}
            className="mx-2"
          >
            <div style={{ position: "relative" }}>
              <SplideTrack className="pt-10 pb-10 mb-2">
                {slides.map((slide, index) => (
                  <SplideSlide key={index} className="splide-slide-img">
                    <div className='bg-[#DBE7E8] rounded-md py-6 h-[400px]'>
                        <div className="flex items-center mx-2 pt-2">
                            <img  className="icon " src={slide.src} alt={slide.alt} />
                            <h2 className='text-darkGreen text-2xl'>{slide.title}</h2>
                        </div>
                        <p className="mx-5 pt-4 text-darkGreen text-xl">{slide.text}</p>
                    </div>
                </SplideSlide>
                ))}
              </SplideTrack>
            </div>
          </Splide>
        </div>
      </div>
    </>
  );
};

export default Goals;
