import limpeza from '../../assets/services/cleaning.png';
import monitoramento from '../../assets/services/monitoramento.png';
import recepcao from '../../assets/services/recepcao.png';


const slides = [
    {
        title: 'Serviços',
        text: 'Nossa empresa oferece soluções abrangentes, desde monitoramento eletrônico e montagem de centrais até serviços especializados de limpeza, pós-obra, fachadas e tratamento de pisos.',
        src: limpeza,
        alt: 'Limpeza',
    },
    {
        title: 'Serviços',
        text: 'Nosso compromisso é oferecer serviços de alta qualidade, desenvolvendo soluções sob medida que atendam aos desafios únicos de cada cliente. Nosso foco é garantir que nossos clientes possam dedicar-se plenamente às suas atividades finais, sabendo que suas demandas estão em mãos experientes e confiáveis. ',
        src: recepcao,
        alt: 'Recepção',
    },
    {
        title: 'Serviços',
        text: ' Acreditamos que ao aliviar as preocupações operacionais de nossos clientes, eles poderão concentrar seus esforços no crescimento e no desenvolvimento de seus negócios.Nossa equipe está empenhada em ser um suporte confiável e estratégico, auxiliando-os no caminho rumo ao sucesso e prosperidade. ',
        src: monitoramento,
        alt: 'Monitoramento ',
    },
];

export default slides;