import './header.css';
import decoration from '../../assets/header/decoration.png';
import iconFind from '../../assets/header/find.svg';

import Navbar from './navbar/Navbar';

const Header = () => {
    return (
        <>
    <div className=''>
       <Navbar/>

        <div className='md:mt-[100px] sm:mt-5 lg:mt-[250px]'>
            <div className='mx-10 header-margin-mobile' >
                <h1 className='pt-11 text-7xl header-font-mobile text-darkGreen headline '>Profissionalismo de quem faz,<br /> aliado
                    à <span className='bg-diasGreen'>competência</span> <br /> de quem entende 
                </h1>
                <div className='mx-1 mt-7 flex bg-diasGreen rounded-md w-[15rem] h-[4rem]'>
                    <img src={iconFind} alt="icon"/>
                    <button className='py-2 px-4 hover:shadow-lg text-xl text-darkGreen'><a href="#Servicos">Explorar</a></button>
                </div>
            </div>
        </div>
    </div>
    <img src={decoration} className='decoration ' alt="decoration"/>
        </>
    )
}

export default Header;
