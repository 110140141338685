import About from "../../components/about/About";
import Goals from "../../components/goals/Goals";
import Header from "../../components/header/Header";
import Partners from "../../components/partners/Partners";
import Services from "../../components/services/Services";
import Clients from "../../components/clients/Clients";
import Contact from "../../components/contact/Contact";
import Footer from "../../components/footer/Footer";

const HomePage = () => {
    return (
        <>
        <main>
            <Header />
            <About />
            <Goals />
            <Services />
            <Clients />
            <Contact />
            <Footer />
        </main>
        </>
    )
}

export default HomePage;
